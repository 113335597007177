<template>
	<div class="pd_introduce">
		<div class="pd_introduce_l">
			<div class="pd_introduce_line"> </div>

			<div class="pd_introduce_line_red">
				<div class="pd_introduce_line_red_sub"></div>
			</div>
			<div class="pd_introduce_title"> Polkadot </div>

			<div class="pd_introduce_content">
				Polkadot, co-founded by Dr. Gavin Wood and the Web 3.0 Foundation, is a
				blockchain protocol that connects various blockchains into a unified
				decentralised network, where users control their data. Its key features
				include interoperability, scalability, shared security, and flexibility
				via the Substrate framework. Its architecture includes a central relay
				chain, parachains, and bridges, supported by a robust nominated PoS
				consensus mechanism. Polkadot’s governance involves DOT token holders,
				an elected council, and a technical committee who together propose and
				vote on network changes. It supports diverse use cases like DeFi, supply
				chain management, gaming, and IoT.
			</div>
			<div class="pd_introduce_img">
				<div
					class="pd_introduce_img_1 pointer"
					@click="showUrl('https://polkadot.network/')"
				>
					<img
						src="https://zy.metaera.media/assets/images/event/35.png"
						alt=""
					/>
				</div>
				<div class="pd_introduce_img_2">
					<img
						src="https://zy.metaera.media/assets/images/event/10.png"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div class="pd_introduce_r">
			<div class="pd_introduce_line"> </div>

			<div class="pd_introduce_line_red">
				<div class="pd_introduce_line_red_sub"></div>
			</div>
			<div class="pd_introduce_title"> MetaEra </div>

			<div class="pd_introduce_content">
				MetaEra is a leading media platform in the Web 3.0 space with a strong
				presence in Hong Kong, Southeast Asia, North America, and the Middle
				East. Our international team and extensive market resources drive
				information dissemination and industry growth in Web 3.0. <br />MetaEra
				reaches over 500,000 users worldwide, with a total readership exceeding
				500 million. We have organized 30+ influential industry summits and
				formed strong alliances with global investment institutions, prominent
				Web 3.0 organizations, industry media, and communities. <br />Through
				our summits, online events, and information platform, MetaEra provides
				valuable industry insights to a global audience. We look forward to
				working with you to usher in a new era of Web 3.0.
			</div>
			<div class="pd_introduce_img">
				<div
					class="pd_introduce_img_1 pointer"
					@click="showUrl('https://www.metaera.hk/')"
				>
					<img
						src="https://zy.metaera.media/assets/images/event/35.png"
						alt=""
					/>
				</div>
				<div class="pd_introduce_img_2">
					<img
						src="https://zy.metaera.media/assets/images/event/9.png"
						alt=""
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { getToken, getUserMobile } from "@/utils/auth";
import { getMap } from "echarts";
export default {
	name: "",
	created() {},
	data() {
		return {
			currentIndex: 0,
		};
	},
	methods: {
		showUrl(url) {
			window.open(url, "");
		},
	},
	watch: {},
};
</script>
<!-- pd_introduce -->
<style scoped>
.pd_introduce {
	/* margin-left: 40px; */
	/* margin-right: 40px; */
	padding-left: 40px;
	padding-right: 40px;
	display: flex;
	justify-content: space-between;
	background: white;
}
.pd_introduce_l,
.pd_introduce_r {
	width: 50%;
	padding-top: 70px;
	padding-bottom: 90px;
	padding-left: 60px;
	padding-right: 60px;
}
.pd_introduce_l {
	display: flex;
	flex-direction: column;
}
.pd_introduce_r {
	display: flex;
	flex-direction: column;
}
.pd_introduce_line {
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_introduce_line_red {
	display: flex;
	justify-content: left; /* 水平居中 */
}

.pd_introduce_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #e6007a;
}
.pd_introduce_title {
	margin-top: 50px;
	font-family: DINPro-Bold;
	font-size: 40px;
	font-weight: bold;
	line-height: 28px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #333333;
}
.pd_introduce_content {
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: DINPro-Regular, DINPro;
	font-size: 18px;
	font-weight: normal;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-align: justify;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #333333;
}
.pd_introduce_img {
	padding-left: 0px;
	padding-right: 0px;
	height: auto;
	margin-top: auto;
	flex: 0 1 auto;
}

.pd_introduce_img_1 {
	width: 152px;
	height: 27px;
	margin-left: 5px;
	margin-bottom: 20px;
}
.pd_introduce_img_1 img {
	width: 100%;
	height: auto;
}
.pd_introduce_img_2 {
	border-radius: 20px;
	width: 100%;
	height: auto;
	margin-top: auto;
	flex: 0 1 auto;
}
.pd_introduce_img_2 img {
	border-radius: 20px;
	width: 100%;
	height: auto;
}
@media screen and (max-width: 900px) {
	.pd_introduce {
		margin-left: 40px;
		margin-right: 40px;
		display: contents;
	}
	.pd_introduce_l,
	.pd_introduce_r {
		width: auto;
		padding-top: 37px;
		padding-bottom: 10px;
		padding-left: 0px;
		padding-right: 0px;
		margin-left: 20px;
		margin-right: 20px;
	}
	.pd_introduce_title {
		margin-top: 20px;
		font-size: 20px;
		display: block;
		align-items: center;
		align-content: center;
		text-align: center;
	}
	.pd_introduce_content {
		font-size: 14px;
		margin-left: 20px;
		margin-right: 20px;
		text-align: center;
	}
	.pd_introduce_line_red {
		display: flex;
		justify-content: center; /* 水平居中 */
	}
	.pd_introduce_img {
		padding-left: 16px;
		padding-right: 16px;
	}
	.pd_introduce_img_1 {
		width: 100%;
		height: 27px;
		margin-left: 28px;
		margin-bottom: 20px;
		text-align: center;
	}
	.pd_introduce_img_1 img {
		width: auto;
		height: 100%;
	}
}
</style>
